import React from 'react';
import moment from 'moment';
import { PicklistProgressBar } from '../picklist-progress-bar/picklistProgressBar';
import Button, { ButtonThemes } from '../../../../ui-components/button/button';
import { ConvertPicklistToRouteState, PicklistPdfState } from '../../../../slices/dispatch-planning/picklists/picklistsState';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPicklistPdf, picklistPdfSelector } from '../../../../slices/dispatch-planning/picklists/picklistPdfSlice';
import { convertPicklistToRoute, convertPicklistToRouteSelector } from '../../../../slices/dispatch-planning/picklists/convertPicklistToRouteSlice';
import { toast } from 'react-toastify';
import { ReactComponent as DownloadIcon } from "../../../../images/download.svg";
import { ReactComponent as EditIcon } from "../../../../images/edit_icon.svg";

export interface PicklistHeaderProps {
  terminalNumber: number;
  picklistNumber: number;
  driverName: string;
  trailerNumber: number;
  lastLoadedAt: string;
  totalItemsCount: number;
  loadedItemsCount: number;
  isLateLoading: boolean;
  onClose: () => void;
}

const PicklistHeader: React.FC<PicklistHeaderProps> = (props) => {
  const dispatch = useDispatch();

  const formatLastLoadedAt = (lastLoadedAt: string, isLateLoading: boolean) => {
    let lastLoadedMessage = "Not Loaded yet";
    if (lastLoadedAt) {
      let lastLoaded = moment(lastLoadedAt, "MMM D YYYY hh:mm");
      lastLoadedMessage = `${lastLoaded.format("MMM D, YYYY")} ${lastLoaded.format("h:mm A")}`;
    }

    return <span className={ (isLateLoading) ? "--late-loading" : ""}>
      {lastLoadedMessage}
    </span>;
  }

  const picklistPdfState: PicklistPdfState = useSelector(
    picklistPdfSelector
  );
  const convertPicklistToRouteState: ConvertPicklistToRouteState = useSelector(
    convertPicklistToRouteSelector
  );
  const getPicklistPdf = () => {
    if (!props.picklistNumber) {
      return;
    }
    dispatch(downloadPicklistPdf(props.picklistNumber));
  }
  const picklistToRoute = () => {
    if (!props.picklistNumber) {
      return;
    }
    let picklistNumber = props.picklistNumber;
    let terminalNumber = props.terminalNumber;
    dispatch(convertPicklistToRoute(
      picklistNumber,
      terminalNumber,
      () => { toast.info("Picklits is converted to a Route successfully!")},
      (error) => { toast.error(error) },
      () => { props.onClose();}
    ))
  }
  return (
    <div className="picklist-details__header">
      <div className="picklist-details__header__title">Picklist# {props.picklistNumber}</div>
      <div className="picklist-details__header__actions">
        <Button
          theme={ButtonThemes.blue}
          className="picklist-details__header__actions__download-pdf-button"
          disabled={(picklistPdfState.loading)}
          spinner={(picklistPdfState.loading)}
          onClick={getPicklistPdf}
        >
          {!picklistPdfState.loading && (
            <div className="xgs-btn__icon-container">
              <DownloadIcon className="xgs-btn__icon" />
            </div>)
          }
          Download Picklist
        </Button>
        <Button
          theme={ButtonThemes.blue}
          className="picklist-details__actions__edit-button"
          disabled={convertPicklistToRouteState.loading}
          spinner={convertPicklistToRouteState.loading}
          onClick={picklistToRoute}
        >
          {!convertPicklistToRouteState.loading && (
            <div className="xgs-btn__icon-container">
              <EditIcon className="xgs-btn__icon" />
            </div>)
          }
          Edit as Route
        </Button>
      </div>
      <div className="picklist-details__header__info">
        <div className="picklist-details__header__group--1">
          <div className="header-info-item">
            <span
              className="header-info-item__title"
            >Driver:</span>
            {props.driverName}
          </div>
          <div className="header-info-item">
            <span
              className="header-info-item__title"
            >Trailer#:</span>
            {props.trailerNumber}
          </div>
        </div>
        <div className="picklist-details__header__group--2">
          <div className="header-info-item">
            <span
              className={"header-info-item__title"}
            >Last Loaded At:</span>
            {formatLastLoadedAt(props.lastLoadedAt, props.isLateLoading)}
          </div>
          <div className="header-info-item">
            <span
              className={"header-info-item__title"}
            >Loading Progress:</span>
            <span className="header-info-item__progress-bar">
              <PicklistProgressBar
                totalItemsCount={props.totalItemsCount}
                loadedItemsCount={props.loadedItemsCount}
                progressTitle="Items"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PicklistHeader