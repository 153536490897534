import moment from "moment";
import React from "react";

const getPicklistsColumns = (
) => [
    {
        Header: "Picklist # / Description",
        accessor: "picklistNumber",
        width: 136,
        Cell: (cellProps: any) => {
            return (
                <div className="cell__picklist-identifiers">
                    <div className="picklist-number">{cellProps.value}</div>
                    <div>{cellProps.row.original.picklistDesc}</div>
                </div>
            )
        }
    },
    {
        Header: "Trailer #",
        accessor: "trailerNumber",
        width: 88,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Assigned Driver",
        accessor: "driverName",
        width: 128,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Probills",
        accessor: "probillsCount",
        width: 80,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Items",
        accessor: "totalItemsCount",
        width: 70,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Weight (Lbs)",
        accessor: "totalWeight",
        width: 102,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Loading Progress (Items)",
        accessor: "loadedItemsCount",
        width: 280,
        Cell: (cellProps: any) => {

            let loadedItemsCount = cellProps.value;
            let totalItemsCount = cellProps.row.original.totalItemsCount;
            let progressFloatValue = 0;

            if (totalItemsCount && totalItemsCount > 0 && loadedItemsCount) {
                progressFloatValue = Math.floor((loadedItemsCount / totalItemsCount) * 100)
            }

            return (
                <div className="cell__progress-container">
                    <span className="cell__progress-info">
                        <span className="cell__progress-percentage">{progressFloatValue}%</span>
                        <span className="cell__progress">{loadedItemsCount}/{totalItemsCount}</span>
                    </span>
                    <div className={"cell__progress-bar" + (progressFloatValue === 100 ? " --completed" : "")}>
                        <div style={{ width: progressFloatValue + "%" }}></div>
                    </div>
                </div>
            )
        }
    },
    {
        Header: "Created by / Created at",
        accessor: "createdBy",
        width: 128,
        Cell: (cellProps: any) => {
            if (!cellProps.row.original.createdAt) {
                return (
                    <div className="cell__picklist-creation-details">
                        <div>{cellProps.value}</div>
                        <div></div>
                    </div>
                )
            } else {
                const date = moment(cellProps.row.original.createdAt, "MMM D YYYY hh:mm");
                return (
                    <div className="cell__picklist-creation-details">
                        <div className={"cell__picklist-creation-details__username"}>{cellProps.value}</div>
                        <div>
                            <div>{date.format("MMM D, YYYY")}</div>
                            <div className="text-light">{date.format("h:mm A")}</div>
                        </div>
                    </div>
                )
            }
        }
    },
    {
        Header: "Missing Items",
        accessor: "missingItemsCount",
        width: 80,
        Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
        Header: "Last Loaded at",
        accessor: "lastLoadedAt",
        width: 128,
        Cell: (cellProps: any) => {
            let lastLoadedMessage = <div
                className={cellProps.row.original.isLateLoading ? " --late-loading" : ""}
            >Not Loaded yet</div>;
            if (cellProps.value) {
                const date = moment(cellProps.value, "MMM D YYYY hh:mm").utc();
                lastLoadedMessage = <> <div
                    className={cellProps.row.original.isLateLoading ? " --late-loading" : ""}
                >{date.local().format("MMM D, YYYY")}</div>
                    <div
                        className={cellProps.row.original.isLateLoading ? " --late-loading" : "text-light"}
                    >{date.local().format("h:mm A")}</div></>
            }
            return (
                <div
                    className={"cell__last-loaded"}
                >
                    {lastLoadedMessage}
                </div>
            )
        }
    }
];

export default getPicklistsColumns;