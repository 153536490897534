import React from "react";
import { useSelector } from "react-redux";
import Table from "../../../../ui-components/table/table";
import CustomCell, {
  CustomCellTypes
} from "../../../../ui-components/table/custom-cells/customCell";
import BlockPlaceholder from "../../../../ui-components/block-placeholder/blockPlaceholder";
import { InvoiceDetailsItem } from "../../../../app/data/invoice/models";
import {
  ContentItem,
  ContentItemTitle
} from "../../../../templates/content-item/contentItem";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import { UserUtils } from "../../../../app/data/user/userUtils";
import AccountNavLink from "../../../../ui-components/account-nav-link/accountNavLink";

export interface InvoiceDetailsTableProps {
  items?: InvoiceDetailsItem[];
  invoiceAmount?: number;
}

const parseProbills = (descriptionItem: string) => {
  if (!descriptionItem) return null;
  const probillRegex = /(\b\d{6,8}\b(\b|$))/gi;
  let descriptionItemBlocks = descriptionItem.split(probillRegex);
  let result = descriptionItemBlocks.map(block => {
    if (block.match(probillRegex)) {
      return <AccountNavLink
        to={"/shipments/" + block}
        target="_blank"
        className="xgs-invoice-details__table__link"
        rel="noopener noreferrer">
          {block}
        </AccountNavLink>;
    } else {
      return block;
    }
  });
  return result;
};

const InvoiceDetailsTable: React.FC<InvoiceDetailsTableProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const columns = [
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Pieces
        </div>
      ),
      accessor: "pieces",
      width: 60,
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          CC
        </div>
      ),      
      accessor: "commodityCode",
      width: 50,
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value}</div>
    },
    {
      width: 308,
      Header: "Description",
      accessor: "description",
      Cell: (cellProps: any) => parseProbills(cellProps.value)
    },
    {
      Header: "Units",
      accessor: "units",
      width: 60
    },
    {
      grow: 0,      
      Header: (
        <div style={{ textAlign: "right" }}>
          <span title="Quantity">Qty</span>
        </div>
      ),
      accessor: "quantity",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Weight
        </div>
      ),
      accessor: "weight",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          {UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
            <>
              Rate
            </>
          )}          
        </div>
      ),      
      accessor: "rate",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "right" }}>
          {UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
            <>
              {cellProps.value}
            </>
          )}
        </div>
      ),
      width: UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") ? 100 : 5
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          {UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
            <>
              Freight charges
            </>
          )}
        </div>
      ),       
      accessor: "freightCharges",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "right" }}>
          {UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
            <CustomCell
              cellType={CustomCellTypes.amount}
              selector="freightCharges"
              data={cellProps.row.original}
              value={cellProps.value || "0"}
            />
          )}
        </div>
      ),
      width: UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") ? 100 : 5
    },
  ];

  return (
    <ContentItem className="xgs-invoice-details__table">
      <ContentItemTitle>Invoice Details</ContentItemTitle>
      {props.items && (
        <Table
          columns={columns}
          data={props.items}
          totals={UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") ? [
            {
              title: "Total Charges",
              type: CustomCellTypes.amount,
              value: props.invoiceAmount || "0"
            },
          ] : undefined}
        />
      )}
      {!props.items && <BlockPlaceholder />}
    </ContentItem>
  );
};

export default InvoiceDetailsTable;
