import React from "react";
import { useSelector } from "react-redux";
import { NavLink, NavLinkProps } from "react-router-dom";

import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";

const AccountNavLink: React.FC<NavLinkProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const subAccountPath = userState?.activeSubAccount?.accountNumber
    ? `/${userState.activeSubAccount.accountNumber}`
    : "";

  return (
    <NavLink
      {...props}
      to={`${subAccountPath}${props.to}`}
    >
      {props.children}
    </NavLink>
  )
}

export default AccountNavLink;