import React, { memo } from "react";
import AgingItem, { getAgingMeta } from "../../aging/item/agingItem";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { useSelector, useDispatch } from "react-redux";
import { agingSelector } from "../../../slices/aging/agingSlice";
import { getProperty } from "../../../hooks/utils";
import {
  AgingState,
  AgingInvoicesState,
  AgingInvoicesPaymentState,
} from "../../../slices";
import { agingInvoicesSelector } from "../../../slices/aging/agingInvoicesSlice";
import { getMultiplePaymentInfo } from "../../../slices/payment/paymentSlice";
import { agingInvoicesPaymentSelector } from "../../../slices/aging/agingInvoicesPaymentSlice";
import DownloadPDF from "../../download-invoice-pdf/downloadPDF";
import AgingInvoicesFilter from "./agingInvoicesFilter";
import { InvoiceFilterModel } from "../../../app/data/invoice/models";

export interface AgingInvoicesHeaderProps {
  agingPeriod: string;
  onSearch: (filter: InvoiceFilterModel) => void;
}

const AgingInvoicesHeader: React.FC<AgingInvoicesHeaderProps> = memo(
  ({ agingPeriod, ...props }) => {
    const agingState: AgingState = useSelector(agingSelector);
    const agingInvoicesState: AgingInvoicesState = useSelector(
      agingInvoicesSelector
    );
    const agingInvoicesPaymentState: AgingInvoicesPaymentState = useSelector(
      agingInvoicesPaymentSelector
    );
    const dispatch = useDispatch();

    const getAgingValue = () => {
      const agingMeta = getAgingMeta(agingPeriod);
      if (agingMeta && agingState.data) {
        return getProperty(agingState.data as any, agingMeta.field);
      }
    };

    const disablePayButton = () => {
      return (
        agingInvoicesState.invoices.filter((invoice) => !invoice.paid)
          .length === 0
      );
    };

    const getInvoiceNumbers = () => {
      let invoiceNumbers: number[] = [];
      if (isPaySelected() && agingInvoicesPaymentState.selectedInvoiceNumbers) {
        invoiceNumbers = [...agingInvoicesPaymentState.selectedInvoiceNumbers];
      } else {
        invoiceNumbers = agingInvoicesState.invoices
          .filter((invoice) => !invoice.paid)
          .map((invoice) => invoice.invoiceNumber);
      }
      return invoiceNumbers;
    };

    const getPaymentSum = () => {
      let invoiceNumbers: number[] = [];
      invoiceNumbers = getInvoiceNumbers();
      const paymentSum = (agingInvoicesState.invoices.length > 0)
        ? agingInvoicesState.invoices
          .filter((invoice) => invoiceNumbers.indexOf(invoice.invoiceNumber) >= 0)
          .map((invoice) => invoice.openAmount)
          .reduce((sum, curr) => sum + curr)
        : undefined;
      return paymentSum;
    };

    const pay = async () => {
      let invoiceNumbers: number[] = [];
      invoiceNumbers = getInvoiceNumbers();
      dispatch(getMultiplePaymentInfo({ invoiceNumbers }, getPaymentSum()));
    };

    const isPaySelected = () => {
      return (
        agingInvoicesPaymentState.selectedInvoiceNumbers &&
        agingInvoicesPaymentState.selectedInvoiceNumbers.length > 0
      );
    };

    return (
      <div className="xgs-aging-invoices__header">
        <div className="xgs-aging-invoices__header__item">
          {!isPaySelected() && (
            <AgingItem
              period={agingPeriod}
              value={getAgingValue()}
              disableLinkToInvoices={true}
            />
          )}
          {isPaySelected() && (
            <AgingItem
              period="_selected"
              value={getPaymentSum()}
              disableLinkToInvoices={true}
            />
          )}
          <AgingInvoicesFilter onSearch={props.onSearch} key={agingPeriod}/>
        </div>

        <div className="xgs-aging-invoices__header__buttons">
          <Button
            theme={ButtonThemes.blue}
            onClick={pay}
            disabled={disablePayButton()}
          >
            Pay {isPaySelected() ? "Selected" : "All"}
          </Button>
          <div className="xgs-aging-invoices__header__download-pdf">
            <DownloadPDF invoiceNumbers={agingInvoicesPaymentState.selectedInvoiceNumbers} type="multiple" />
          </div>
        </div>
      </div>
    );
  }
);

export default AgingInvoicesHeader;
