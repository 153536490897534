import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";

import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Tag from "../../../ui-components/molecules/tag/tag";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";

import { customerPickupSelector, getPickups } from "../../../slices/customer-pickup/customerPickupSlice";
import { getCustomerPickupSourceLabel, getStatusColor, getStatusesOptions } from "../../../services/pickups";
import { CustomerPickupsFilterModel, CustomerPickupsFilterSchema } from "../../../app/data/customer-pickup/models";

import "./customerPickupsList.scss";
import { PickupStatus } from "../../../app/data/common/route";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { Routes } from "../../../app/route/RoutesConfig";
import { PathParams, PickupsPath } from "../route";
import { useParams } from "react-router-dom";
import CustomerPickupDetails from "./customerPickupDetails";

const initialFilterValues: CustomerPickupsFilterModel = {
  pickupNumber: "",
  dateFrom: undefined,
  dateTo: undefined,
  statuses: [PickupStatus.ASSIGNED, PickupStatus.UNASSIGNED,  PickupStatus.MISSED],
};

const CustomerPickupsList: React.FC<{}> = (props) => {  
  const customerPickupState = useSelector(customerPickupSelector);
  const filterFormRef = useRef<FormikProps<CustomerPickupsFilterModel>>(null);
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const [filter, setFilter] = useState<CustomerPickupsFilterModel>(initialFilterValues);

  const { param: pickupNumber, setSearchParam, resetSearchParam } = useSearchParam(Routes.pickups.list, PickupsPath.pickupNumber);
  const pickup = customerPickupState.pickups.find((pickup) => pickup.pickupNumber === pickupNumber);

  const columns = [
    {
      width: 112,
      minWidth: 112,
      Header: "Pickup #",
      accessor: "pickupNumber",
      Cell: (cellProps: any) => (
        <span className="text-blue">
          {cellProps.value}
        </span>
      )
    },    
    {
      width: 112,
      minWidth: 112,
      Header: "Source",
      accessor: "creatorType",
      Cell: (cellProps: any) => (
        <>
          { getCustomerPickupSourceLabel(cellProps.value) }
        </>
      )
    },
    {
      width: 220,
      minWidth: 220,
      Header: "Time of Availability",
      accessor: "pickup.date",
      Cell: ({ row }: any) => {
        const date = row.original.pickupDate;
        const readyTime = row.original.pickupReadyTime;
        const closeTime = row.original.pickupCloseTime;

        return (
        <div>          
          <div>
            {date ? date.toUiDateFormat() : ""}
            {<span className="text-light xgs-table__cell-subitem">{moment(readyTime, "hh:mm:ss").format("h:mm A")} - {moment(closeTime, "hh:mm:ss").format("h:mm A")}</span>}
          </div>
        </div>
      )}
    },
    {
      width: 446,
      minWidth: 446,
      Header: "Pickup Address",
      accessor: "shipper.address.address1",
      Cell: ({ row }: any) => (
        <>
        <div>
          {row.original.shipper?.address.address1 ? `${row.original.shipper?.address.address1}, ` : ""}{row.original.shipper?.address.city ? `${row.original.shipper?.address.city}, ` : ""}{row.original.shipper?.address.state ? `${row.original.shipper?.address.state}, ` : ""}{row.original.shipper?.address.postalCode}
        </div>
        {row.original.shipper?.address.additionalAddressLine && <div>
          {row.original.shipper?.address.additionalAddressLine}
        </div>}
        </>
        
      )
    },
    {
      width: 110,
      minWidth: 110,
      Header: "Status",
      accessor: "pickupStatus",
      Cell: (cellProps: any) => (
        <Tag mods={{color: getStatusColor(cellProps.value), uppercase: true}}>
          { cellProps.value }       
        </Tag>
      )
    },
  ];

  const onSubmitFilter = (values: CustomerPickupsFilterModel) => {
    setFilter(values);
    dispatch(getPickups(values));
    resetSearchParam();
  };

  const onListInfiniteScroll = () => {
    if (customerPickupState.pickupsRequest?.last) return;
    
    dispatch(getPickups(filter, customerPickupState.pickupsRequest ? customerPickupState.pickupsRequest.page + 1 : undefined));
  }

  useEffect(() => {
    if (params[PickupsPath.pickupNumber]) {
      filterFormRef.current?.setValues({
        pickupNumber: params[PickupsPath.pickupNumber],
        statuses: null,
        dateFrom: undefined,
        dateTo: undefined,
      });
      filterFormRef.current?.submitForm();
    } else {
      dispatch(getPickups(initialFilterValues));
    };
    
  }, [dispatch, params]);

  return (   
      <ContentContainer
        className="xgs-list xgs-list--full-width"
        mods={{ 'full-width': true }}
        titleComponent={
          <ContentContainerToolbar
            title="Pickups"
            mods={{ "full-width": true }}
          />
        }
      >
        <div className="xgs-customer-pickups-list">
          <div className="xgs-list__controls">
            <div className="xgs-list__controls__search">
              <Formik
                initialValues={initialFilterValues}
                onSubmit={onSubmitFilter}
                innerRef={filterFormRef}
                validationSchema={CustomerPickupsFilterSchema}
              >
                 {(props: FormikProps<CustomerPickupsFilterModel>) => (
                <Form className="xgs-list__controls__search-form">
                  <XGSFormInput
                    type="text"
                    name="pickupNumber"
                    label="Pickup #"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                    placeholder="e.g. PU00012345"
                  />
                  <LabeledDateRangeInput
                    label="Dates Range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field xgs-list__controls__date-range"
                    start={props.values.dateFrom}
                    end={props.values.dateTo}
                    onStartChange={(v) => {
                      props.setFieldValue("dateFrom", v || undefined);
                      props.submitForm()
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("dateTo", v || undefined);
                      props.submitForm()
                    }}
                  />
                  <XGSFormSelect
                      name="statuses"
                      label="Status"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field xgs-item-form__field_lg"
                      options={getStatusesOptions()}
                      isClearable  
                      isMulti
                      controlled={true}
                      onMultiValuesChange={() => {props.submitForm()}}                      
                    />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    className=""
                    spinner={false}
                  >
                    Search
                  </Button>
                </Form>
                )}
              </Formik>
            </div>
          </div>
          {(customerPickupState.requestFailed && customerPickupState.requestFailStatus && customerPickupState.requestCreator === "GET_PICKUPS") && (
        <XGSErrorMessage>{customerPickupState.requestError}</XGSErrorMessage>
      )}
      {!(customerPickupState.requestFailed && customerPickupState.requestFailStatus && customerPickupState.requestCreator === "GET_PICKUPS") && (
        <Table
          isLoading={customerPickupState.requestStarted && customerPickupState.requestCreator === "GET_PICKUPS"}
          keepTableOnLoading
          columns={columns}
          data={customerPickupState.pickups}
          cursorPointer
          onRowClicked={(row: any) => {setSearchParam(row.pickupNumber)}}
          rowHeight={56}
          minTableHeight={240}
          noResultsText="There are no pickups"
          infiniteScroll
          infiniteScrollLoading={customerPickupState.fetchPortionStarted && customerPickupState.requestCreator === "GET_PICKUPS_PORTION"}
          infiniteScrollHasNext={!customerPickupState.pickupsRequest?.last}
          onInfiniteScroll={onListInfiniteScroll}          
          responsive
          highlightRow={pickupNumber ? customerPickupState.pickups.findIndex((pickup) => pickup.pickupNumber === pickupNumber) : null}
        />
      )}     
        <CustomerPickupDetails
          show={!!pickup}
          pickup={pickup}
          onClose={resetSearchParam}
        />   
        </div>
      </ContentContainer>
    );
};

export default CustomerPickupsList;