import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import "./reportDetails.scss";
import ContentContainer from "../../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import Loading from "../../../../ui-components/loading/loading";
import XGSFormCheckbox from "../../../../ui-components/form/checkbox/xgsFormCheckbox";
import { Routes } from "../../../../app/route/RoutesConfig";
import { PathParams, ReportsPath } from "../../route";
import { ReportModel, ReportSchema } from "../../../../app/data/reports/models";
import ReportsState from "../../../../slices/reports/ReportsState";
import {
  createReport,
  deleteReport,
  getReports,
  reportsSelector,
  resetReportsState,
  runReport,
  updateReport
} from "../../../../slices/reports/reportsSlice";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import { frequencyTypes, dayOfWeek, fields } from "../constants";

const ReportDetails: React.FC<{}> = (props) => {
  const params = useParams() as PathParams;
  const reportsState: ReportsState = useSelector(reportsSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const reportFormRef = useRef<any>(null);
  const [frequencyFormValue, setFrequencyFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();
  const [dayOfWeekFormValue, setDayOfWeekFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();
  const [deleteConfirmOpen, setdeleteConfirmOpen] = useState<boolean>(false);
  const reportId = params[ReportsPath.id];
  let reportData = reportId ? reportsState?.reports?.find((report: ReportModel) => report.id === reportId) : null;
  let initialValues: ReportModel = (reportId && reportData)
    ? {
        id: reportData.id,
        dateCreated: reportData.dateCreated,
        userUpdated: reportData.userUpdated,
        dateUpdated: reportData.dateUpdated,
        userCreated: reportData.userCreated,
        name: reportData.name,
        subject: reportData.subject,
        message: reportData.message,
        frequency: reportData.frequency,
        runDay: reportData.runDay,
        email1: reportData.email1,
        email2: reportData.email2 || "",
        email3: reportData.email3 || "",
        fields: reportData.fields,
        subAccountId: reportData.subAccountId
      }
    : {
        id: "",
        dateCreated: "",
        userUpdated: "",
        dateUpdated: "",
        userCreated: "",
        name: "Report",                         // these values are needed here to prevent the initial Formik error state,
        subject: "Report",                      // which doesn't change after setFieldValue for some reasons
        message: "Please find attached report", // (possible Formik issue)
        frequency: "DAILY",
        runDay: "MONDAY",
        email1: userState.profile?.email || "",
        email2: "",
        email3: "",
        fields: {
          PROBILL: true,
          BILL_DATE: true,
          PROBILL_STATUS: true
        },
        subAccountId: ""
      };

  const onSubmitReport = (data: ReportModel) => {
    if (!userState.activeSubAccount || !userState.activeSubAccount.id) return
    data.subAccountId = userState.activeSubAccount.id;
    if (reportId) {
      dispatch(updateReport(`${userState.activeSubAccount.id}`, data, () => {
        toast.info("The report was updated!", { autoClose: 4000 });
        goToList();
      }));
    } else {
      dispatch(createReport(`${userState.activeSubAccount.id}`, data, () => {
        toast.info("New report was created!", { autoClose: 4000 });
        goToList();
      }));
    }
  };

  const onDeleteReport = () => {
    if (!userState.activeSubAccount) return
    dispatch(deleteReport(`${userState.activeSubAccount.id}`, reportId, () => {
      toast.info("The report was deleted!", { autoClose: 4000 });
      goToList();
    }));
  };

  const onRunReport = () => {
    if (!userState.activeSubAccount) return
    dispatch(runReport(`${userState.activeSubAccount.id}`, reportId, () => {
      toast.info("The report was sent!", { autoClose: 4000 });
    }));
  };

  const goToList = () => {
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.reports.scheduled.list}`);
  }

  const discardChanges = () => {
    dispatch(resetReportsState());
    goToList();
  };

  const updateFields = (frequency?: string) => {
    if (reportId) return;
    const accountName = userState.activeSubAccount?.name;
    const frequencyLabel = frequencyTypes.find(type => type.value === frequency)?.label
    if (reportFormRef.current && frequencyLabel) {
      !reportFormRef.current.touched.name && reportFormRef.current.setFieldValue("name", `${frequencyLabel} report`);
      !reportFormRef.current.touched.subject && reportFormRef.current.setFieldValue("subject", `${frequencyLabel} report - ${accountName}`);
      !reportFormRef.current.touched.message && reportFormRef.current.setFieldValue("message", `Please find attached ${frequencyLabel.toLowerCase()} report for ${accountName}`);
    }
  };

  useEffect(() => {
    if (reportId) {
      if (reportData) {
        const frequencyLabel = frequencyTypes?.find(type => type.value === reportData?.frequency)?.label;
        const dayOfWeekLabel = dayOfWeek?.find(type => type.value === reportData?.runDay)?.label;
        reportData.frequency && setFrequencyFormValue({
          label: frequencyLabel || "",
          value: reportData.frequency
        });
        reportData.runDay && setDayOfWeekFormValue({
          label: dayOfWeekLabel || "",
          value: reportData.runDay
        });
      } else {
        if (!userState.activeSubAccount) return
        dispatch(getReports(`${userState.activeSubAccount.id}`));
      }
    } else {
      setFrequencyFormValue({
        label: "Daily",
        value: "DAILY"
      });
      setDayOfWeekFormValue({
        label: "Monday",
        value: "MONDAY"
      });
      updateFields("DAILY");
    }
    // eslint-disable-next-line
  }, [reportId, reportData, dispatch, userState.activeSubAccount, reportsState.reports]);  

  useEffect(() => {
    if (!reportsState.requestFailed) return;
    toast.error(reportsState.requestError || "Error", { autoClose: 4000 });
  }, [reportsState.requestFailed, reportsState.requestError]);

  useEffect(() => {
    if (!userState.activeSubAccount?.id || !userState.profile?.subAccounts) return;
    if (!userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor) {
      history.push(Routes.home);
    }
  }, [history, userState.profile, userState.activeSubAccount]);

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar
          title={reportId ? "Edit Report" : "New Report"}
        >
          <>
            {reportId && !(reportsState.requestStarted && reportsState.requestCreator === "run") && (
              <div
                className="xgs-site__content-container__toolbar__buttons__item"
                onClick={onRunReport}>
                <XGSIcon
                  icon={XGSIcons.faEnvelope}
                  size="lg"
                  className="xgs-site__content-container__toolbar__buttons__item__icon"
                /> <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">Send now</span>
              </div>
            )}
            {reportId && reportsState.requestStarted && reportsState.requestCreator === "run" && (
              <div className="xgs-site__content-container__toolbar__buttons__item">
                <Loading isLoading={true} className="xgs-site__content-container__toolbar__buttons__item__icon" />
                <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">Sending...</span>
              </div>
            )}
          </>
        </ContentContainerToolbar>
      }
    >
      {(reportsState.requestStarted && reportsState.requestCreator === "list") && (
        <div className="xgs-reports__scheduled__list-spinner">
          <Loading isLoading={true} />
        </div>
      )}
      {(!reportsState.requestStarted || reportsState.requestCreator !== "list") && (
        <div>
          <Formik
            onSubmit={onSubmitReport}
            initialValues={initialValues}
            validationSchema={ReportSchema}
            innerRef={reportFormRef}
            enableReinitialize
          >
            {(props: FormikProps<ReportModel>) => (
              <Form>
                <XGSFormInput
                  type="text"
                  name="name"
                  label="Report name:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-reports__scheduled__name-field xgs-reports__scheduled__field xgs-reports__scheduled__field--long"
                />
                <div className="xgs-reports__scheduled__section">Schedule</div>
                <div className="xgs-reports__scheduled__field-group">
                  <LabeledSelectInput
                    isSearchable={false}
                    label="Send report:"
                    labelMode={LabelModes.column}
                    name="frequency"
                    value={frequencyFormValue}
                    onValueChange={(v) => {
                      props.setFieldValue("frequency", v?.value);
                      updateFields(v?.value);
                      setFrequencyFormValue(v);
                    }}
                    options={frequencyTypes}
                    required={true}
                    requiredAsteriskDisabled={true}
                    formik={true}
                    className="xgs-reports__scheduled__frequency-field"
                  />
                  {props.values.frequency === "WEEKLY" && (
                    <LabeledSelectInput
                      isSearchable={false}
                      label="Day of week:"
                      labelMode={LabelModes.column}
                      name="runDay"
                      value={dayOfWeekFormValue}
                      onValueChange={(v) => {
                        props.setFieldValue("runDay", v?.value);
                        setDayOfWeekFormValue(v);
                      }}
                      options={dayOfWeek}
                      required={true}
                      requiredAsteriskDisabled={true}
                      formik={true}
                      className="xgs-reports__scheduled__dow-field"
                    />
                  )}
                </div>
                <div className="xgs-reports__scheduled__section">Report fields</div>
                <div className="xgs-reports__scheduled__fields__label" style={{ marginBottom: 2 }}>Fields to include in report:</div>
                <div className="xgs-reports__scheduled__fields__columns">
                  <div className="xgs-reports__scheduled__fields__column">
                    <div className="xgs-reports__scheduled__fields__column__header">Probill Details</div>
                    {fields.filter(field => field.section === "probill").map((field) =>
                      <XGSFormCheckbox
                        key={field.name}
                        name={`fields.${field.name}`}
                        disabled={field.default}
                      >
                        {field.label}
                      </XGSFormCheckbox>
                    )}
                  </div>
                  <div className="xgs-reports__scheduled__fields__column">
                    <div className="xgs-reports__scheduled__fields__column__header">Roll/Item Details<sup>1</sup></div>
                    {fields.filter(field => field.section === "roll/item").map((field) =>
                      <XGSFormCheckbox
                        key={field.name}
                        name={`fields.${field.name}`}
                        disabled={field.default}
                      >
                        {field.label}
                      </XGSFormCheckbox>
                    )}
                  </div>
                </div>
                <div className="xgs-reports__scheduled__notes">
                  <sup>1</sup> <strong>Note:</strong> adding Roll/Item parameters will create additional report entries for each item.
                </div>
                <div className="xgs-reports__scheduled__section">Recipients</div>
                <XGSFormInput
                  type="text"
                  name="email1"
                  label="Email 1:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-reports__scheduled__email-field xgs-reports__scheduled__field"
                />
                <XGSFormInput
                  type="text"
                  name="email2"
                  label="Email 2:"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-reports__scheduled__email-field xgs-reports__scheduled__field"
                />
                <XGSFormInput
                  type="text"
                  name="email3"
                  label="Email 3:"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-reports__scheduled__email-field xgs-reports__scheduled__field"
                />
                <div className="xgs-reports__scheduled__section">Customize email</div>
                <XGSFormInput
                  type="text"
                  name="subject"
                  label="Email subject:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-reports__scheduled__subject-field xgs-reports__scheduled__field xgs-reports__scheduled__field--long"
                />
                <XGSFormTextarea
                  name="message"
                  label="Email message:"
                  required={true}
                  rows={6}
                  className="xgs-reports__scheduled__field xgs-reports__scheduled__field--long"
                />
                <div className="xgs-reports__scheduled__form-buttons">
                  <Button
                    theme={ButtonThemes.blue}
                    spinner={reportsState.requestStarted && reportsState.requestCreator === "save"}
                    disabled={!props.isValid || !props.dirty}
                    className="xgs-reports__scheduled__save-button"
                  >
                    Save report
                  </Button>
                  <Button
                    theme={ButtonThemes.gray}
                    className="xgs-reports__scheduled__discard-button"
                    onClick={discardChanges}
                    type="button"
                  >
                    Discard changes
                  </Button>
                  {params[ReportsPath.id] && (
                    <Button
                      theme={ButtonThemes.gray}
                      className="xgs-reports__scheduled__delete-button"
                      onClick={() => { setdeleteConfirmOpen(true); }}
                      type="button"
                    >
                      Delete report
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <ConfirmationModal
        opened={deleteConfirmOpen}
        header="Delete report"
        confirmButtonText="Delete report"
        spinner={reportsState.requestStarted && reportsState.requestCreator === "delete"}
        onCancel={() => { setdeleteConfirmOpen(false); }}
        onConfirm={() => { onDeleteReport(); }}
      >
        This report will be permanently deleted.<br />
        Deleted report cannot be restored.
      </ConfirmationModal>
    </ContentContainer>
  );
};

export default ReportDetails;
