import React from "react";

import "./agingItem.scss";
import { getProperty } from "../../../hooks/utils";
import { agingPeriods, IAgingMeta } from "../../../app/data/aging/models";
import { Routes } from "../../../app/route/RoutesConfig";
import Amount from "../../../ui-components/molecules/amount/amount";
import AccountNavLink from "../../../ui-components/account-nav-link/accountNavLink";

export interface AgingItemProps {
  value?: number;
  period: string;
  disableLinkToInvoices?: boolean;
}

export const getAgingMeta = (period: string): IAgingMeta | null => {
  let result: IAgingMeta | null = null;
  Object.keys(agingPeriods).forEach((key: any) => {
    const currentAgingMeta = getProperty(agingPeriods, key) as IAgingMeta;
    if (currentAgingMeta.period === period) {
      result = currentAgingMeta;
      return;
    }
  });
  return result;
};

const AgingItem: React.FC<AgingItemProps> = (props) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.disableLinkToInvoices) e.preventDefault();
  };

  return (
    <AccountNavLink
      to={`${Routes.agingInvoices}/${props.period}`}
      className="xgs-aging-item"
      onClick={handleClick}
    >
      <div
        className={`xgs-aging-item__title xgs-aging-item__title${props.period}`}
      >
        {getAgingMeta(props.period)?.title}
      </div>
      <span
        className={`xgs-aging-item__value ${
          props.period === agingPeriods.total.period ? "bold" : ""
        }`}
      >
        <Amount amount={props.value} />
      </span>
    </AccountNavLink>
  );
};

export default AgingItem;
