import React, { useEffect, useMemo, memo, useRef } from "react";
import { CSVLink } from "react-csv";
import Helmet from "react-helmet";
import { AgingInvoicesState } from "../../../slices";
import UserState from "../../../slices/user/UserState";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "../../../slices/user/userSlice";
import Table from "../../../ui-components/table/table";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import { useParams, useHistory } from "react-router-dom";
import { PathParams, AgingInvoicesPath } from "./route";
import Invoice, { InvoiceFilterModel } from "../../../app/data/invoice/models";
import { Routes } from "../../../app/route/RoutesConfig";
import getAgingInvoicesColumns from "./agingInvoicesColumns";
import { getAging } from "../../../slices/aging/agingSlice";
import {
  getAgingInvoices,
  getAgingInvoicesForCSV,
  agingInvoicesSelector,
} from "../../../slices/aging/agingInvoicesSlice";
import InvoicePayment from "../invoice-payment/invoicePayment";
import AgingInvoicesHeader from "./agingInvoicesHeader";
import { setSelectedInvoiceNumbers } from "../../../slices/aging/agingInvoicesPaymentSlice";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import "./agingInvoices.scss";

const AgingInvoices: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const agingInvoicesState: AgingInvoicesState = useSelector(agingInvoicesSelector);
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const history = useHistory();
  const appliedFilter = useRef<InvoiceFilterModel | null>(null);
  
  const agingPeriod = useMemo(() => {
    return params[AgingInvoicesPath.period];
  }, [params]);

  const csvLinkRef = React.useRef<any>();

  useEffect(() => {     
    appliedFilter.current = null;
  }, [ agingPeriod ]);

  useEffect(() => {
    dispatch(getAging(userState.activeSubAccount?.id));
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, appliedFilter.current));
    dispatch(setSelectedInvoiceNumbers([]));
  }, [
    dispatch,
    userState.activeSubAccount,
    agingPeriod,
    agingInvoicesState.needToReload,
  ]);

  const onRowClick = (row: Invoice) => {
    history.push(getInvoiceDetailsUrl(row));
  };

  const getInvoiceDetailsUrl = (row: Invoice) => {
    return `/${userState.activeSubAccount?.accountNumber}${Routes.invoices}/${row.invoiceNumber}`;
  };

  const selectedInvoicesChanged = React.useCallback(
    (state: {
      selectedRowsData: Invoice[];
    }) => {
      const numbers: number[] = state.selectedRowsData.map(
        (selectedRow) => selectedRow?.invoiceNumber
      );
      dispatch(setSelectedInvoiceNumbers(numbers));
    },
    [dispatch]
  );

  const csvHeaders = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "BOL Number", key: "bolNumber" },
    { label: "PO Number", key: "poNumber" },
    { label: "Invoice Date", key: "dateInvoice" },
    { label: "Due Date", key: "dateDue" },
    { label: "Invoice Amount", key: "invoiceAmount" },
    { label: "Open Amount", key: "openAmount" }
  ];

  const fetchCSV = () => {
    dispatch(getAgingInvoicesForCSV(userState.activeSubAccount?.id, agingPeriod, downloadCSV));
  };

  const preparedCSV = () => {
    return (agingInvoicesState.invoicesCSV.length > 0)
      ? agingInvoicesState.invoicesCSV.filter(invoice => !invoice.paid).map(({ subAccountId, paid, ...csvColumns }) => csvColumns)
      : [];
  }

  const downloadCSV = () => {
    if (csvLinkRef?.current) {
      csvLinkRef.current.link.click();
    }
  }

  const searchInvoices = (filter: InvoiceFilterModel) => {
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, filter));
    appliedFilter.current = filter;
  };

  const onInfiniteScroll = () => {
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, appliedFilter.current, agingInvoicesState.lastIds || undefined, true));
  };

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar
          title="Invoices"
        >
          <>
            <Helmet title="Invoices" />
            {!agingInvoicesState.loadingCSV && (
              <>
                <div
                  className="xgs-site__content-container__toolbar__buttons__item"
                  onClick={fetchCSV}>
                  <XGSIcon
                    icon={XGSIcons.faFileDownload}
                    size="lg"
                    className="xgs-site__content-container__toolbar__buttons__item__icon"
                  /> <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">Download CSV</span>
                </div>
                <CSVLink
                  ref={csvLinkRef}
                  filename={`invoices_${userState.activeSubAccount?.accountNumber}${agingPeriod}.csv`}
                  headers={csvHeaders}
                  style={{ display: "none" }}
                  data={preparedCSV()}></CSVLink>
              </>
            )}
            {agingInvoicesState.loadingCSV && (
              <Loading isLoading={agingInvoicesState.loadingCSV} />
            )}
          </>
        </ContentContainerToolbar>
      }
    >
      <div className="xgs-aging-invoices">
        <AgingInvoicesHeader agingPeriod={agingPeriod} onSearch={searchInvoices}/>        
        <Table
          isLoading={agingInvoicesState.loading}
          columns={getAgingInvoicesColumns(getInvoiceDetailsUrl)}
          data={agingInvoicesState.invoices}
          onRowClicked={onRowClick}
          ignoreCellClick="selection"
          cursorPointer={true}
          onSelectedRowsChange={selectedInvoicesChanged}
          infiniteScroll={true}
          infiniteScrollLoading={agingInvoicesState.loadingPortion}
          infiniteScrollHasNext={!agingInvoicesState.loadedAll}
          onInfiniteScroll={onInfiniteScroll}
        />
      </div>
      <InvoicePayment />
    </ContentContainer>
  );
});

export default AgingInvoices;
